import {logout} from '../../../../services/user-service';
import * as style from './menu-profile.module.scss'
import React from 'react'



const MenuProfile = () => {
    const logoutUser = (event) => {
        event.preventDefault();
        logout()
    }

    return <div className={style.container}>
        <a href="/account/my-profile">Settings</a>
        <a onClick={logoutUser}>Logout</a>
    </div>
}

export default MenuProfile