export const likeStorage = {
    save: (articleId) => {
        let likes = JSON.parse(localStorage.getItem('articleLikes')) || [];
        likes.push(articleId)
        localStorage.setItem('articleLikes', JSON.stringify(likes))
    },
    isArticleLiked: (articleId) => {
        let likes = JSON.parse(localStorage.getItem('articleLikes'));
        if(!likes) {
            return false
        }
        return likes.find(item => parseInt(item) === parseInt(articleId)) && true
    }
}

export const adsStorage = {
    save: (storage) => {
        localStorage.setItem('advertisements', JSON.stringify(storage))
    },
    get: () => {
        const advertisements = JSON.parse(localStorage.getItem('advertisements'))
        return advertisements ? advertisements : null
    }
}