import HamburguerButton from '../../atoms/hamburger-button/hamburguer-button';
import React, { useState } from 'react'
import Emitter from '../../../../services/emitter';
import * as style from './submenu-navigation-desktop.module.scss'


const SubmenuNavigationDesktop = ({children, menuItems}) => {
    const [toogleMenu, setToogleMenu] = useState(false)

    const toogleEventShowMenu = (menu) => {
        if(menu) {
            Emitter.emit('OPEN_MENU_SPORTS_DESKTOP')
        }else {
            Emitter.emit('CLOSE_MENU_SPORTS_DESKTOP')
        }
    }

    const eventMenu = () => {
        toogleEventShowMenu(!toogleMenu);
        setToogleMenu(!toogleMenu);
    }

    return <div className={style.content}>
        <HamburguerButton handleClick={eventMenu} open={toogleMenu}/>
        <div className={style.clubs}>
            {menuItems.map(item => <a href={`/${item.to}`}>{item.description}</a>)}
        </div>
        {children}
    </div>

}

export default SubmenuNavigationDesktop