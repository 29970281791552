// extracted by mini-css-extract-plugin
export var formSections = "register-form-module--formSections--TX3P9";
export var section = "register-form-module--section--hwhoH";
export var fields = "register-form-module--fields--VckmG";
export var submitClub = "register-form-module--submitClub--DOSA8";
export var emptyBox = "register-form-module--emptyBox--eHOtx";
export var formClub = "register-form-module--formClub--Y7shz";
export var tip = "register-form-module--tip--UpkaW";
export var submit = "register-form-module--submit--fNplf";
export var form_club = "register-form-module--form_club--gjmKN";
export var conditions = "register-form-module--conditions---JvnE";
export var errorBox = "register-form-module--errorBox--ZW-Qy";
export var linkTerms = "register-form-module--linkTerms--ciTl6";
export var terms = "register-form-module--terms--RyPku";
export var password = "register-form-module--password--CpPdR";
export var confirmationPassword = "register-form-module--confirmationPassword--fwjhv";
export var fullname = "register-form-module--fullname--Tg6gn";
export var checkboxes = "register-form-module--checkboxes--mUtTh";