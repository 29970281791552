import React, { useEffect, useState } from "react"
import * as style from './menu-sports.module.scss'
import Emitter from "../../../../services/emitter"
import { useI18next, useTranslation } from "gatsby-plugin-react-i18next"
import arrow from '../../../../images/arrow-left-solid.svg'

const MenuSportsMobile = ({menus = []}) => {
    const translate = useTranslation().t;
    const [showMenuSportsMobile, setShowMenuSportsMobile] = useState(false)
    const [showSubmenu, setShowSubmenu] = useState(false)

    const [subMenus, setSubmenus] = useState([])

    const enableSubMenu = (submenu) => {
        setSubmenus(submenu)
        setShowSubmenu(true)
    }

    const disableSubMenu = () => {
        setSubmenus([])
        setShowSubmenu(false)
    }

    const redirect = (url) => {
        disableSubMenu();
        Emitter.emit('CLOSE_MENU_SPORTS_MOBILE');
        window.location.replace(url);
    }

    useEffect(() => {
        Emitter.on('OPEN_MENU_SPORTS_MOBILE', () => setShowMenuSportsMobile(true))
        Emitter.on('CLOSE_MENU_SPORTS_MOBILE', () => {
            setShowMenuSportsMobile(false)
            setShowSubmenu(false)
        })
        Emitter.on('CLOSE_SUBMENU_SPORTS_MOBILE', () => setShowSubmenu(false))
    }, [])

    return showMenuSportsMobile && 
        <div className={style.main}>
            {!showSubmenu && <div className={style.sectionAllSports}>
                <ul>
                <li className={style.allSportsItem} onClick={() => setShowMenuSportsMobile(false)}><img src={arrow} className={style.arrowBack} /> Menu</li>
                    {menus.map(item => <li onClick={() => enableSubMenu(item.clubs)} key={item.id}>{item.sport}<span className={style.arrow}>></span></li>)}
                </ul>
            </div>}
            {
                showSubmenu && <div className={style.sectionAllSports}>
                <ul>
                    <li className={style.allSportsItem} onClick={() => disableSubMenu()}><img src={arrow} className={style.arrowBack} /> {translate('menu.allSports')}</li>
                    {subMenus.map(item => <li onClick={() => redirect(item.link)} key={item.id}>{item.club}</li>)}
                </ul>
            </div>
            }
        </div>

}


export default MenuSportsMobile