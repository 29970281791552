import React, { useEffect, useRef, useState } from 'react';
import axios from 'axios'

import NavigationDesktop from '../../../core-module/molecules/navigation-desktop/navigation-desktop'
import NavigationMobile from '../../molecules/navigation-mobile/navigation-mobile'
import * as style from './header.module.scss'
import { useI18next } from 'gatsby-plugin-react-i18next';
import { createUrlSlug, urlGgeneratedByLanguage } from "../../../../utils/utils"
import MenuSportsDesktop from '../../molecules/menu-sports/menu-sports-desktop'
import MenuSportsMobile from '../../molecules/menu-sports/menu-sports-mobile'
import { loadMenu } from "../../../../services/menu-service"
import Skeleton from '../../../main-page-module/organisms/skeleton/skeleton';
import OverHeader from '../../../advertisments-module/molecules/over-header/over-header';
import SearchBar from '../../../main-page-module/atoms/search-bar/search-bar';
import Emitter from '../../../../services/emitter';
import SubmenuNavigationDesktop from '../../molecules/navigation-desktop/submenu-navigation-desktop';
import SearchMenuButton from '../../atoms/search-menu-button/search-menu-button';
import NotificationBar from '../../molecules/notification-bar/notification-bar';
import MenuProfile from './menu-profile';



const Header = () => {
  
  const [nOfUnseenNotifications, setNOfUnseenNotifications] = useState(null)
  const [menuItemsDesktop, setMenuItemsDesktop] = useState([])
  const [menuItemsMobile, setMenuItemsMobile] = useState([])
  const [menuData, setMenuData] = useState([])
  const [showSearchBar, setShowSearchBar] = useState(false)
  const [viewProfileMenu, setViewProfileMenu] = useState(false)
  const [viewNotificationBar, setViewNotificationBar] = useState(false)
  const { language } = useI18next()
  const ref = useRef(null)
  const [fixed, setFixed] = useState('');

  const addFanpagesLink = (menus) => {
    return [...menus, {description: 'Fanpages', to:'main/fanpages'}]
  }

  const setTop5Menu = (menu) => {
    if(localStorage.getItem('top5menu')) {
      return
    }

    let menus = [
    ]

    menu.slice(0, 9).map(item => menus.push({ description: item.title, to:  `main/entity/${createUrlSlug(item.title)}?id=${item.id}`
    , icon: '' }))

    menus = addFanpagesLink(menus)

    setMenuItemsDesktop(menus)
    setMenuItemsMobile([{ description: 'Home', to:  '/' }, { description: 'All Sports', action: 'openMenuMobile', icon: 'biking' },...menus])
  }

  const loadMenuData = async () => {
    const menus = await loadMenu(language)
    setMenuData(menus)
  }

  const menuIsFixedAndPageIsScrolling = (position) => position.top < 0 && fixed !== ''

  const handleScroll = () => {
    const element = ref && ref.current && ref.current.children ? ref.current.children[0] : null
    if (element) {
        let position = element.getBoundingClientRect()

        if(menuIsFixedAndPageIsScrolling(position)) {
          return;
        }

        setFixed(position.top < 0 && fixed === '' ? style.fixed : '')
    }
  }

  useEffect(() => {

    //gets notifications for first load 
    const isBrowser = () => typeof window !== "undefined"
    isBrowser && window.addEventListener('scroll', handleScroll)
    const userId = isBrowser() && window !== 'undefined' && JSON.parse(window.localStorage.getItem("tokenId"))

    axios.get(`${urlGgeneratedByLanguage(language)}/api/top-5-sports`).then((response) => {
      setTop5Menu(response.data)
    })

    loadMenuData()

    if (!userId) return
    axios.get(`${urlGgeneratedByLanguage(language)}/api/get-notification-data?uid=${userId}&seen_status=0`).then((response) => {
      setNOfUnseenNotifications(response.data.data.length)
    })
  }, [])

  useEffect(() => {
    Emitter.on('OPEN_MENU_SPORTS_DESKTOP', ()=> {
      setViewNotificationBar(false);
    })
  }, [])

  return (
      <div className={style.content} ref={ref}>
        <div className={style.navigationMobile}>
          <MenuSportsMobile menus={menuData} />
          <div className={`${style.navigationMenuContent} ${fixed}`}>
            <div className={'container ' + style.navigationMenu}>  
              <NavigationMobile menuItems={menuItemsMobile} counter={nOfUnseenNotifications} updateCounter={setNOfUnseenNotifications} />
            </div>
          </div>
        </div>
        <div className={style.navigationDesktop}>
          <div className={`${style.navigationMenuContent} ${fixed}`}>
            <Skeleton className={style.blackBanner}>
              <NavigationDesktop 
                counter={nOfUnseenNotifications} 
                toogleNotificationBar={setViewNotificationBar} 
                toogleProfile={setViewProfileMenu}
              />
              {viewProfileMenu && <MenuProfile />}
            </Skeleton>

            {viewNotificationBar && <Skeleton className={style.blackBanner}>
              <NotificationBar updateCounter={setNOfUnseenNotifications} />
            </Skeleton>}

            <Skeleton className={style.greenBanner}>
              <SubmenuNavigationDesktop menuItems={menuItemsDesktop}>
                <SearchMenuButton 
                    open={() => {
                      setShowSearchBar(true);
                      setViewNotificationBar(false);
                    }} 
                    close={() => setShowSearchBar(false)}
                />
              </SubmenuNavigationDesktop>
            </Skeleton>
          </div>
          <Skeleton>
            <MenuSportsDesktop menus={menuData} />
          </Skeleton>
          {showSearchBar && <div className={style.searchBar} style={{'top': showSearchBar ? '100px' : '0px'}}>
          <div className={style.contentSearch}>
            <SearchBar />
          </div>
        </div>}
        </div>
        <div className={style.overHeaderContent}>
          <OverHeader />
        </div>
      </div>
  )
}

export default Header
