// extracted by mini-css-extract-plugin
export var blackBanner = "header-module--blackBanner--pEXcs";
export var greenBanner = "header-module--greenBanner--g2VeE";
export var navigationMenu = "header-module--navigationMenu--g2t-+";
export var navigationDesktop = "header-module--navigationDesktop--kCf2E";
export var navigationMobile = "header-module--navigationMobile--VASq-";
export var content = "header-module--content--r0JmP";
export var overHeaderContent = "header-module--overHeaderContent--WFZgY";
export var fixed = "header-module--fixed--K-7g+";
export var navigationMenuContent = "header-module--navigationMenuContent--kef7K";
export var searchBar = "header-module--searchBar--WrsLI";
export var blackbackground = "header-module--blackbackground--+4O+q";