// extracted by mini-css-extract-plugin
export var advertisement = "advertisement-module--advertisement--535jR";
export var advertisementTop = "advertisement-module--advertisementTop--50bcR";
export var advertisementFooter = "advertisement-module--advertisementFooter--PgEOc";
export var advertisementSide = "advertisement-module--advertisementSide--9qpPJ";
export var advertisementASide = "advertisement-module--advertisementASide--bcrx6";
export var advertisementMid = "advertisement-module--advertisementMid--76Aw3";
export var advertisementClubArticle = "advertisement-module--advertisementClubArticle--OP5SJ";
export var advertisementMiddleText = "advertisement-module--advertisementMiddleText--0pE-7";
export var advertisementRandom = "advertisement-module--advertisementRandom--ViTve";
export var advertisementEndText = "advertisement-module--advertisementEndText--cCSyk";