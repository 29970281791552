import axios from 'axios'
import { createUrlSlug, urlGgeneratedByLanguage } from '../utils/utils'

const isLocalStorageAvailable = () => typeof window !== "undefined" && window

const clubFieldMapping = (data) => {
    if(data && Array.isArray(data)) {
        return data.map(({sport_id, sport_title, sport_logo_url, sport_logo_alt}) => ({
            id: sport_id,
            club: sport_title,
            logo: sport_logo_url,
            logo_alt: sport_logo_alt,
            link: `/main/entity/${createUrlSlug(sport_title)}?id=${sport_id}`
        }))
    }
    return []
}

const filterSubmenus = (submenus, menus) => {
    const submenuIds = Object.keys(submenus);
    let menuList = []
    submenuIds.forEach(id => {
        let itemMenu = menus.find(menu => parseInt(menu.id) === parseInt(id))
        if(itemMenu) {
            itemMenu['clubs'] = clubFieldMapping(submenus[id])
            menuList.push(itemMenu)
        }
    })
    // cacheMenu(menuList)
    return menuList;
}

const cacheMenu = (menu) => {
    if(isLocalStorageAvailable()) {
        window.localStorage.setItem('menu', JSON.stringify(menu))
    }
}

const getCachedMenu = () => {
    return isLocalStorageAvailable() && JSON.parse(window.localStorage.getItem('menu'))
}

export const loadMenu = (language) => {
    const menu = getCachedMenu()
    if(!menu) {
        return axios.all([
            axios.get(urlGgeneratedByLanguage(language)+'/api/get-category-data'),
            axios.get(urlGgeneratedByLanguage(language)+'/api/get-sport-category-data')
        ]).then(result => {
            const categoryData = result[0].data.data || []
            const sportCategoryData = result[1].data.data || []
            let menus = categoryData.map(({id, category}) => ({
                id: id,
                sport: category,
                clubs: []
            }))
            return filterSubmenus(sportCategoryData, menus)
        })
    }

    return menu;
}