import Emitter from "./emitter"
import {AdsRequestAdapter} from "../components/advertisments-module/atoms/advertisement/advertisement";
import axios from 'axios'
import { urlGgeneratedByLanguage } from "../utils/utils";
import { adsStorage } from "./storage-service";
const isBrowser = () => typeof window !== "undefined"
let lat = ''
let long = ''

function loadCoordinates() {
    return new Promise(function(resolve, reject) {
        window.navigator.geolocation.getCurrentPosition(resolve, reject);
    });
}

async function getCoordinates() {
    try {
        if(!lat || !long) {
            const result = await loadCoordinates()
            if(result) {
                setCoordinates(result)
            }
        }
    }catch(error) {
        console.log('was not possible to get coordinates')
    }
}

if (isBrowser() && window.navigator && window.navigator.geolocation) {
    getCoordinates()
}

function setCoordinates(position) {
    lat = position.coords.latitude
    long = position.coords.longitude
}

export function readAndGenerateTopAd(ads) {
    const topAds = AdsRequestAdapter(ads.find(ad => ad?.position === '0'));
    Emitter.emit('TOP_AD_EVENT', topAds);
}

export function getTopAd(ads) {
    return ads.find(ad => parseInt(ad?.position) === 0)
}

export function getUniqueSideAds(ads, amountAdsRequired = 0) {
    let allSideAds = ads.filter(ad => parseInt(ad.position) === 1)

    let lastPossibleAdArrayPosition = allSideAds.length - amountAdsRequired

    let randomStartArrayPosition = Math.floor(Math.random() * Math.max(0,lastPossibleAdArrayPosition))
    return allSideAds.slice(randomStartArrayPosition, (randomStartArrayPosition + amountAdsRequired))
}

export function getHomeTopAd(ads) {
    return ads.find(ad => parseInt(ad?.position) === 0 && parseInt(ad?.home) === 1)
}

export function getMiddleTextAd(ads) {
    const middleAds = ads.find(ad => parseInt(ad?.position) === 2)
    Emitter.emit('MIDDLE_AD_EVENT', middleAds);
    return middleAds
}

export function getHomeMiddleTextAd(ads) {
    const middleAds = ads.find(ad => parseInt(ad?.position) === 2 && parseInt(ad?.home) === 1)
    Emitter.emit('MIDDLE_AD_EVENT', middleAds);
    return middleAds
}

export function getSideAd(ads) {
    return ads.find(ad => parseInt(ad?.position) === 1)
}

export function getHomeSideAd(ads) {
    return ads.find(ad => parseInt(ad?.position) === 2 && parseInt(ad?.home) === 1)
}

export function randomPickOneAdCampaign(ads) {
    const grouped = {}
    ads.forEach(item => grouped[item.position] == undefined ? grouped[item.position] = [item] : grouped[item.position].push(item))
    return Object.keys(grouped).map(adPosition => grouped[adPosition][Math.floor(Math.random() * adPosition.length)])
}

function isThereAdInTheSlot(slot) {
    return slot && slot['media_link']
}

const shuffle = list => list.sort(() => Math.random() - 0.5)

export function adsObjectStruct (ads){
    //const position = [1,2,3] //ads position
    const position = [0,1,2]
    const list = []
    const shuffledList = shuffle(ads)
    shuffledList.forEach((item) => {
        if (parseFloat(item.remaining_budget) > 0) {
            for (let elt in position) {

                if(isThereAdInTheSlot(item[position[elt]])) {
                    let adElet = { 
                        ... item[position[elt]], 
                        id: item.advertisement_id, 
                        campaignId: item.campaign_id,
                        url: item.url,
                        title: item.advertisement,
                        home: item.home,
                        budget: item.remaining_budget,
                        position: Object.keys(item)[elt],
                    }

                    list.push(adElet)
                }
            }               
        }
    })


    return shuffle(list)
}

export async function loadManyAds(language, params) {
    const cachedAd = adsStorage.get()
    if(cachedAd && cachedAd.data && cachedAd.lat == lat && cachedAd.long == long) {
        return {data: adsObjectStruct(cachedAd.data)}
    }

    let extraParams = ''
    await getCoordinates()
    if(lat && long) {
        extraParams = `&latitude=${lat}&longitude=${long}`
    }

    return axios.get(`${urlGgeneratedByLanguage(language)}/api/get-all-campaign-advertisements?${params}${extraParams}`).then(result => {
        return new Promise((resolve) => {
            adsStorage.save(result.data);
            resolve({data: adsObjectStruct(result.data)})
        })
    })
}

export async function loadAds(language, params) {
    const cachedAd = adsStorage.get()
    let extraParams = ''

    await getCoordinates()
    if(lat && long) {
        extraParams = `&latitude=${lat}&longitude=${long}`
    }

    if(cachedAd && cachedAd.data && cachedAd.lat == lat && cachedAd.long == long) {
        return {data: randomPickOneAdCampaign(adsObjectStruct(cachedAd.data))}
    }

    return axios.get(`${urlGgeneratedByLanguage(language)}/api/get-all-campaign-advertisements?${params}${extraParams}`).then(result => {
        return new Promise((resolve) => {
            adsStorage.save({data: result.data, lat, long});
            resolve({data: randomPickOneAdCampaign(adsObjectStruct(result.data))})
        })
    })
}


