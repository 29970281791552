import React from 'react'

import * as footerStyles from './footer.module.scss'
import Newsletter from '../../../main-page-module/molecules/newsletter/newsletter';
import LogoIcon from '../../atoms/logo-icon/logo'

import FooterPrivacyLegalNote from '../../atoms/footer-privacy-legal-note/footer-privacy-legal-note'
import FooterAboutUseNote from '../../atoms/footer-about-us-note/footer-about-us-note'
import SocialNetworkFan2Be from '../../../main-page-module/atoms/social-network/social-network-fan2be';
import Skeleton from '../../../main-page-module/organisms/skeleton/skeleton';




const Footer = () => {

  return <>
    <footer className={`${footerStyles.content} `}>
      <div className={`container ${footerStyles.wrapper}`}>
        <div className={`${footerStyles.first}`}>
          <LogoIcon />
        </div>
        <div className={`${footerStyles.middle}`}>
          <div className={footerStyles.middle__row}>
            <FooterAboutUseNote />
          </div>
        </div>
        <div className={`${footerStyles.last}`}>
          <div>
            <SocialNetworkFan2Be description={'Of volg ons via'} className={footerStyles.socialNetwork}/>
          </div>
        </div>
        <div className={`${footerStyles.last}`}>
          <div>
            <Newsletter />
          </div>
        </div>
      </div>
      <Skeleton className={footerStyles.privacy}>
        <FooterPrivacyLegalNote />
      </Skeleton>
    </footer >
  </>
}
export default Footer