import React from 'react'
import image from '../../../../images/fan2bewebshop.png'

const Fan2BeWebshop = ({className}) => {

    return  <div className={className}>
                <a href={`https://ambishop.be/index.php/fan2be/`} target="_blank">
                    <img src={image} title={'Fan2Be Webshop'} alt={'Fan2Be Webshop'}/>
                </a>
            </div>
}

export default Fan2BeWebshop