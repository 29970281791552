import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import * as style from './search-menu-button.module.scss'
import React, { useState } from 'react'

const SearchMenuButton = ({open, close}) => {

    const [status, setStatus] = useState(false)

    const toogleMenu = () => {
        if(status) {
            setStatus(false);
            close()
        }else {
            setStatus(true);
            open()
        }
    }

    return <div className={style.content}>
            <div className={style.searchIcon} style={{'top': status ? '30px' : '0px'}} onClick={() => toogleMenu()}>
                <FontAwesomeIcon icon={'search'} size="lg" color={'white'} />
            </div>
            <div className={style.hamburguer} style={{'top': !status ? '-30px' : '0px'}} onClick={() => toogleMenu()} >
                <div className={style.lineTop} style={{ 'transform': 'rotate(45deg)'}} />
                <div style={{ opacity: 0, transform: 'translateX(-16px)'}} />
                <div className={style.lineBottom} style={{ 'transform': 'translateX(-1px) rotate(-45deg)'}} />
            </div>
        </div>
}

export default SearchMenuButton;