import { isBrowser, redirect, urlGgeneratedByLanguage } from '../utils/utils'
import axios from 'axios'

export const getUserId = () => (isBrowser() && window !== 'undefined' && JSON.parse(window.localStorage.getItem("tokenId")))

export const logout = (language) => {
    const token_logout = isBrowser() && window && JSON.parse(window.localStorage.getItem("tokenLogout"))
    const csrf_token = isBrowser() && window && JSON.parse(window.localStorage.getItem("tokenCSRF"))
    const header = { 'X-CSRF-Token': csrf_token }
    axios.post(`${urlGgeneratedByLanguage(language)}/user/logout?_format=json&token=${token_logout}`, {}, { headers: header })
        .then(() => {
            isBrowser() && window && window.localStorage.removeItem("tokenLogout")
            isBrowser() && window && window.localStorage.removeItem("tokenCSRF")
            isBrowser() && window && window.localStorage.removeItem("tokenId")
            redirect("/")
        }).catch(err => {
            isBrowser() && window && window.localStorage.removeItem("tokenLogout")
            isBrowser() && window && window.localStorage.removeItem("tokenCSRF")
            isBrowser() && window && window.localStorage.removeItem("tokenId")
            redirect("/")
        })
}
