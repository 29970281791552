import React, { useState, useEffect } from "react"
import { Link, navigate } from "gatsby"

import * as navDesktopStyles from './navigation-desktop.module.scss'

import logoImage from "../../../../images/logo.svg"
import UserAuthentication from "../../../authentication-module/organisms/user-authentication/user-authentication"
import MenuTab from "../menu-tab/menu-tab"
import Emitter from "../../../../services/emitter"
import NotificationBar from "../notification-bar/notification-bar"
import { isUserLoggedIn } from '../../../../utils/check-user-auth'
import SocialNetworkFan2Be from "../../../main-page-module/atoms/social-network/social-network-fan2be"

const NavegationDesktop = ({ className, counter, toogleNotificationBar, toogleProfile }) => {

  const [visibleTab, setVisibleTab] = useState(0);
  const [itemMenuActions, setMenuActions] = useState([])

  useEffect(() => {   
    if ( isUserLoggedIn()) {
      setMenuActions( [
        { id: 2, tabIcon: "notification", icon: 'bell', counter, clickhandle: () => { toogleNotificationBar((current) => !current) } },
        { id: 3, tabIcon: "profile", icon: 'user', label: 'Profiel', clickhandle: () => toogleProfile(current => !current) }
      ])
    } else {
      setMenuActions( [
        { id: 3, tabIcon: "profile", icon: 'user', label: 'Login', clickhandle: () => { setVisibleTab(3) } }
      ])
    }
  }, [visibleTab])


  useEffect(() => {
    Emitter.on('OPEN_SIDE_BAR', function(value) {
        setVisibleTab(value)
        Emitter.emit('RELOAD_COMMENTS')
    })
  }, [Emitter])

  return (
    <div className={navDesktopStyles.container}>
      <div className={`${className} ${navDesktopStyles.content}`} >
        <nav role="navigation" className={navDesktopStyles.navigation}>
          <SocialNetworkFan2Be />
          <Link className={navDesktopStyles.logo} to={"/"}>
            <img src={logoImage} alt="logo" title="Logo"/>
          </Link>
          <div className={navDesktopStyles.menuActions}>
            <MenuTab data={itemMenuActions} visibleTab={visibleTab} />
          </div>
        </nav>
      </div>
      {visibleTab === 3 && <UserAuthentication authenticationScreenStatus={setVisibleTab} ></UserAuthentication>}
    </div>
  )
}

export default NavegationDesktop