import React from 'react'
import * as style from './skeleton.module.scss'

const Skeleton = ({ children, className = style.defaultBackground }) => {
    return <div className={className}>
        <div className={'container ' + style.content}>
            {children}
        </div>
    </div>
}

export default Skeleton
