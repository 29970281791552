import React from 'react'
import * as style from './social-network-fan2be.module.scss'
import instagram from '../../../../images/instagram.svg'
import facebook from '../../../../images/facebook.svg'
import linkedin from '../../../../images/linkedin_home.svg'
import { redirect } from '../../../../utils/utils'

const SocialNetworkFan2Be = ({className, description = 'Follow us!'}) => {

    const redirectNetwork = (social) => {

        const linksSocialNetwork = {
            'linkedin': 'https://www.linkedin.com/company/fan2be/',
            'facebook': 'https://www.facebook.com/fan2be/',
            'instagram': 'https://www.instagram.com/fan2.be/'
        }

        redirect(linksSocialNetwork[social], '_blank')
    }


    return <div className={style.content + ' ' +className}>
        <p className={style.text}>{description}</p>
        <div className={style.networkIcons}>
            <img src={instagram} onClick={() => redirectNetwork('instagram')} />
            <img src={facebook} onClick={() => redirectNetwork('facebook')} />
            <img src={linkedin} onClick={() => redirectNetwork('linkedin')} />
        </div>
    </div>
}

export default SocialNetworkFan2Be;