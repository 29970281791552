import axios from 'axios'


const tokens = {
    newsletter: '22ec7c95-69ca-445f-b28f-e0fdb8b15c87',
    generalRegister: '34b70bd9-c806-4aed-adbb-1938f4872a26',
    fanpage: '74aec912-9e42-4388-b87b-a6c78f3cc4be',
    advertisement: '52a2c386-88ba-43f3-b343-78d02f0bb619'
}


function sendHubSpotInformation(fields, form) {

    const requestObject = {
        fields, 
        submittedAt: (new Date()).getTime()
    }

    axios.post('https://api.hsforms.com/submissions/v3/integration/submit/25429398/'+form, requestObject)

}

function dataObject(name, value, objectType = '1') {
    return {
        "objectTypeId": "0-"+objectType,
        "name": name,
        "value": value
    }
}


export function hubspotNewsletter({email}) {

    const body = [
        dataObject('email', email)
    ]

    sendHubSpotInformation(body, tokens.newsletter)
}

export function hubspotFanPageCreation({club_name,
    firstName,
    lastName,
    email,
    sports,
    postalCode,
}) {

    const body = [
        dataObject('email', email),
        dataObject('firstname', firstName),
        dataObject('lastname', lastName),
        dataObject('postcode', postalCode),
        dataObject('naam_van_de_club', club_name),
        dataObject('offered_sports', sports.map(sport => sport).join(';'), '2'),
    ]

    sendHubSpotInformation(body, tokens.fanpage)

}

export function hubspotGeneratelRegister({email, terms, newsletter}) {
    let dateAccepted = ''
    const fillZero = (time) => ('0'+time).slice(-2)
    if(terms) {
        dateAccepted = new Date()
        let d = dateAccepted.getDate()
        let m = dateAccepted.getMonth() + 1
        let y = dateAccepted.getFullYear()
        dateAccepted = `${fillZero(d)}/${fillZero(m)}/${y}`
    }

    const body = [
        dataObject('email', email),
        dataObject('subscribed_to_newsletter__externally_', newsletter),
        dataObject('fan___accepted_terms___conditions_date', dateAccepted),
        dataObject('fan___accepted_terms___conditions', terms)
    ]

    sendHubSpotInformation(body, tokens.generalRegister)
}

function convertToString(data, characterToSplit = ';') {
    if(typeof data === 'string') {
        return data;
    }

    if(Array.isArray(data)) {
        return data.join(characterToSplit)
    }

    if(!data) {
        return ''
    }

    return JSON.stringify(data)
}

export function hubspotAdvertisementRequest({
    name,
    companyName,
    role,
    email,
    address,
    number,
    postalCode,
    vatNumber,
    phone,
    adsValue,
    adsType,
    adsValueString,
    bus,
    adsPerDay,
    field_region_details,
    field_by_region,
    field_region_post_code,
    field_range,
    field_range_other,
    sports
}) {

    const body = [
        dataObject('email', email),
        dataObject('firstname', name),
        dataObject('companyname', companyName),
        dataObject('function', role),
        dataObject('address', address),
        dataObject('bus', bus),
        dataObject('number', number),
        dataObject('postcode', postalCode),
        dataObject('phone', phone),
        dataObject('advertisement_form___vat_number', vatNumber, '2'),
        dataObject('advertisement_form___target_regions___select_a_region', field_region_details, '2'),
        dataObject('advertisement_form___target_regions___per_region', convertToString(field_by_region), '2'),
        dataObject('advertisement_form___target_regions___per_postcode___postcode', field_region_post_code, '2'),
        dataObject('advertisement_form___target_regions___per_postcode___radius', field_range, '2'),
        dataObject('advertisement_form___target_regions___per_postcode___other_radius', field_range_other, '2'),
        dataObject('advertisement_form___desired_investment_group', adsValue, '2'),
        dataObject('advertisement_form___desired_investment_amount__text_', adsValueString, '2'),
        dataObject('advertisement_form___desired_daily_investment', adsPerDay, '2'),
        dataObject('advertisement_form___target_sports', sports, '2'),
        dataObject('advertisement_form___ad_format', convertToString(adsType), '2')
    ]

    sendHubSpotInformation(body, tokens.advertisement)

}