import React, { useRef } from "react"
import * as  menuTabStyles from './menu-tab.module.scss'
import MenuTabButton from "../../atoms/menu-tab-button/menu-tab-button"
import { scrollToBottom } from "../../../../utils/utils"
import { useTranslation } from 'gatsby-plugin-react-i18next';


const MenuTab = ({ data, visibleTab }) => {
    const bottomRef = useRef()
    const translation = useTranslation().t

    const listTitles = data.map((item, index) =>
        <li key={`menuTabItem${index}`}>
            {
                <MenuTabButton
                    isActive={visibleTab === item.id}
                    clickHandler={item.clickhandle}
                    icon={item.icon}
                    counter={item.counter}
                    label={item.label}
                />
            }
        </li>
    )

    return <>
        <ul className={menuTabStyles.content}>
            <li className={menuTabStyles.desktop}>
                <MenuTabButton
                    clickHandler={scrollToBottom}
                    label={translation('newsletter')}
                />
            </li>
            {listTitles}
        </ul>

    </>
}

export default MenuTab