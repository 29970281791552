import React, { useEffect, useState } from "react"
import * as style from './menu-sports.module.scss'
import Emitter from "../../../../services/emitter"

const MenuSportsDesktop = ({menus = []}) => {
    const [showMenuSportsDesktop, setShowMenuSportsDesktop] = useState(false)
    const [subMenus, setSubmenus] = useState([])

    const closeMenu = () => {
        setSubmenus([])
        setShowMenuSportsDesktop(false)
    }

    useEffect(() => {
        Emitter.on('OPEN_MENU_SPORTS_DESKTOP', () => setShowMenuSportsDesktop(true))
        Emitter.on('CLOSE_MENU_SPORTS_DESKTOP', () => closeMenu())
    }, [])

    return showMenuSportsDesktop && 
        <div className={style.main}>
            <div className={style.sectionAllSports}>
                <h2>All sportes</h2>
                <ul>
                    {menus.map(item => <li onMouseOver={() => setSubmenus(item.clubs)} key={item.id}>{item.sport}<span className={style.arrow}>></span></li>)}
                </ul>
            </div>
            {subMenus.length > 0 && <div className={style.sectionSports}>
                <ul>
                    {subMenus.map(item => <li onClick={() => window.location.replace(item.link)} key={item.id}>{item.club}</li>)}
                </ul>
            </div>
            }
        </div>

}


export default MenuSportsDesktop